<template>
  <tr>
    <td></td>
    <td v-if="showTcFilter">
      <v-text-field
        v-model="filterItem.name_tc__contains"
        outlined
        dense
        clearable
        @change="onChange"
        :clear-icon-cb="onChange"
      ></v-text-field>
    </td>
    <td v-if="showScFilter">
      <v-text-field
        v-model="filterItem.name_sc__contains"
        outlined
        dense
        clearable
        @change="onChange"
        :clear-icon-cb="onChange"
      ></v-text-field>
    </td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
</template>
<script>
import BaseFilter from "./BaseFilter";
export default {
  props: {
    showTcFilter: false,
    showScFilter: false,
  },
  data: (vm) => ({}),
  mixins: [BaseFilter],
  methods: {},
};
</script>