<template>
  <v-container fluid>
    <CommonAlertDialog
      :show="showAlert"
      :item="alertItem"
      v-on:onCloseAlert="onCloseAlert"
    >
    </CommonAlertDialog>
    <v-col class="text-right">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="createItem"
      >
        <v-icon>mdi-account-plus</v-icon>
        {{ $t("create") }}
      </v-btn>
    </v-col>
    <CustomerDialog
      :show="showDialog"
      :action="action"
      :item="dialogItem"
      v-on:onClose="onCloseEvent"
      v-on:onSave="onSaveEvent"
    ></CustomerDialog>
    <base-material-card
      icon="mdi-clipboard-text"
      :title="$t('customer.list')"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="showHeaders"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :footer-props="footerProps"
        multi-sort
      >
        <template v-slot:header>
          <CustomerFilter
          :showTcFilter="isTraditional||isAdmin"
          :showScFilter="isSimplified||isAdmin"
          v-on:onFilterChange="onFilterChange"
          ></CustomerFilter>
        </template>
        <template v-slot:top="{pagination, options, updateOptions}">
          <v-data-footer 
            :pagination="pagination" 
            :options="options"
            @update:options="updateOptions"
            :show-first-last-page="true"
            :show-current-page="true"
            first-icon="mdi-chevron-double-left"
            last-icon="mdi-chevron-double-right"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            :items-per-page-options="[20, 50, 100]"
          />
        </template>
        <template v-slot:item.name="{ item }">{{ 
            get_customer_name(item) 
        }}</template>
        <template v-slot:item.contact_phone="{ item }">
          <template v-for="(contact_phone,key) in parseJsonField(item.contact_phone)">
            {{ contact_phone }}<br>
          </template>
        </template>
        <template v-slot:item.phone="{ item }">
          <template v-for="(phone,key) in parseJsonField(item.phone)">
            {{ phone }}<br>
          </template>
        </template>
        <template v-slot:item.fax="{ item }">
          <template v-for="(fax,key) in parseJsonField(item.fax)">
            {{ fax }}<br>
          </template>
        </template>
        <template v-slot:item.address="{ item }">
          <template v-for="(addr,key) in parseJsonField(item.address)">
            {{ addr }}<br>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="editItem(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="disableItem(item,$t('customer.customer'),item.name_tc)"
              >mdi-block-helper</v-icon>
            </template>
            <span>{{ $t("disable") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CommonAlertDialog from "../dialogs/CommonAlertDialog";
import CustomerDialog from "../dialogs/CustomerDialog";
import BaseTable from "./BaseTable.vue";
import CustomerFilter from "../filters/CustomerFilter"
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    url: "/customer/customer/",
    headersMap: {
      id: {
        sortable: true,
        text: vm.$i18n.t("ID"),
        value: "id",
        width: "5%",
      },
      name_tc: {
        sortable: true,
        text: vm.$i18n.t("customer.name_tc"),
        value: "name_tc",
        width: "10%",
      },
      name_sc: {
        sortable: true,
        text: vm.$i18n.t("customer.name_sc"),
        value: "name_sc",
        width: "10%",
      },
      contact_person: {
        sortable: true,
        text: vm.$i18n.t("customer.contact_person"),
        value: "contact_person",
        width: "8%",
      },
      phone: {
        sortable: false,
        text: vm.$i18n.t("customer.phone"),
        value: "phone",
        width: "8%",
      },
      fax: {
        sortable: false,
        text: vm.$i18n.t("customer.fax"),
        value: "fax",
        width: "8%",
      },
      address: {
        sortable: true,
        text: vm.$i18n.t("customer.address"),
        value: "address",
        width: "15%",
      },
      actions: {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "5%",
      },
    },
    tcHeaders: ['id', 'name_tc', 'contact_person','phone','fax','address',  'actions'],
    scHeaders: ['id', 'name_sc', 'contact_person','phone','fax','address',  'actions'],
    adminHeaders: ['id', 'name_tc', 'name_sc', 'contact_person','phone','fax','address',  'actions'],
    items: [],
    options: {},
    totalItems: 0,
    loading: false,
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100],
    },
    defaultItem: {
      need_invoice: 1
    },
  }),
  mixins: [BaseTable],
  computed: {
    ...mapState(["role","language"]),
    showHeaders () {
      var headers = []
      var headerKeys = this.tcHeaders
      if (this.language==='zhHans') {
        headerKeys = this.scHeaders
      }
      if (this.role===0) {
        headerKeys = this.adminHeaders
      }
      headerKeys.forEach(key => {
        headers.push(this.headersMap[key])
      })
      return headers
    },
  },
  components: {
    CustomerDialog,
    CustomerFilter,
    CommonAlertDialog
  },
  methods: {
    parseJsonField(item) {
      // console.log(item);
      const arr = [];
      if (item) {
        for (const [key, value] of Object.entries(JSON.parse(item))) {
          // console.log(key, value);
          arr.push(value);
        }
      }
      return arr;
    },
    editItem(item) {
      this.orig_dialogItem = this._.cloneDeep(item);
      this.dialogItem = this._.cloneDeep(item);
      // const contact_phone_arr = JSON.parse(this.dialogItem.contact_phone);
      // this.dialogItem.contact_phone = contact_phone_arr["contact_phone1"];
      if (this.dialogItem.phone) {
        const phone_arr = JSON.parse(this.dialogItem.phone);
        this.dialogItem.phone = phone_arr["phone1"];
      }
      if (this.dialogItem.fax) {
        const fax_arr = JSON.parse(this.dialogItem.fax);
        this.dialogItem.fax = fax_arr["fax1"];
      }
      if (this.dialogItem.address) {
        const address_arr = JSON.parse(this.dialogItem.address);
        this.dialogItem.address1 = address_arr["addr1"];
        this.dialogItem.address2 = address_arr["addr2"];
        this.dialogItem.address3 = address_arr["addr3"];
      }
      
      this.action = this.action_items.edit;
      this.showDialog = true;
    },
    get_customer_name(item) {
      return this.getHanText(item, "name");
    },
  },
  mounted() {
    this.getParams['enable'] = true
  },
};
</script>
