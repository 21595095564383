<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row dense>
            <v-col v-if="isTraditional||isAdmin">
              <v-text-field
                v-model="item.name_tc"
                :label="$t('customer.name_tc')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('supplier.name_tc') }}
                </template>
              </v-text-field>
            </v-col>
            <v-col v-if="isSimplified||isAdmin">
              <v-text-field
                v-model="item.name_sc"
                :label="$t('customer.name_sc')"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('supplier.name_sc') }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.contact_person"
                :label="$t('customer.contact_person')"
              ></v-text-field>
            </v-col>
            <!-- <v-col>
              <v-text-field
                v-model="item.contact_phone"
                :label="$t('customer.contact_phone')"
              ></v-text-field>
            </v-col> -->
            <v-col>
              <v-text-field
                v-model="item.box_code"
                :label="$t('customer.box_code')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.phone"
                :label="$t('customer.phone')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="item.fax"
                :label="$t('customer.fax')"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="item.need_invoice"
                :label="$t('customer.invoice')"
                :items="invoice_items"
                :item-text="item => $t(item.text)"
              ></v-select>
            </v-col>
          </v-row>          
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.address1"
                prepend-icon="mdi-map-marker"
                :label="$t('customer.address')+1"
              >
                <template
                  #label
                  v-if="action==action_items.create"
                >
                  <span class="red--text"><strong>* </strong></span>{{ $t('customer.address')+1 }}
                </template></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.address2"
                prepend-icon="mdi-map-marker"
                :label="$t('customer.address')+2"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                v-model="item.address3"
                prepend-icon="mdi-map-marker"
                :label="$t('customer.address')+3"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="disableSave"
          @click="save"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { invoice_items } from "@/definition.js"
import BaseDialog from "./BaseDialog";
export default {
  data: (vm) => ({
    topic: vm.$i18n.t("customer.customer"),
    invoice_items: invoice_items,
  }),
  components: {},
  mixins: [BaseDialog],
  methods: {
    save() {
      // const contact_phone_arr = {
      //   contact_phone1: this.item.contact_phone,
      // };
      // this.item.contact_phone = JSON.stringify(contact_phone_arr);
      if (this.item.phone) {
        const phone_arr = {
          phone1: this.item.phone,
        };
        this.item.phone = JSON.stringify(phone_arr);
      }
      if (this.item.fax) {
        const fax_arr = {
          fax1: this.item.fax,
        };
        this.item.fax = JSON.stringify(fax_arr);
      }
      const address_arr = {
        addr1: this.item.address1,
        addr2: this.item.address2,
        addr3: this.item.address3,
      };
      this.item.address = JSON.stringify(address_arr);
      let editedItem = this._.cloneDeep(this.item);
      this.$emit("onSave", editedItem);
    },
  },
};
</script>
